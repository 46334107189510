/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { Container } from "./styles"
import WhatsApp from "../../../../assets/images/svg/ic-whatsapp-receipt.svg"

const Receipt = () => {
  return (
    <Container>
      <h2>
        <small>confirmação e</small> comprovante
      </h2>
      <p>Confira se todos os dados do comprovante estão corretos e pronto!</p>
      <p style={{ fontWeight: "bold" }}>
        Agora, quando for a igreja, coloque sua mãos no Altar em sinal de honra
        e consideração a Deus.
      </p>
      <p>Ou você também pode enviar seu comprovante através do Whatsapp:</p>

      <div>
        <strong>11 2392 3333</strong>
        <a href="https://wa.me/551123923333">
          <WhatsApp />
        </a>
      </div>
    </Container>
  )
}

export default Receipt
