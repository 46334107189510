/** @jsx jsx */
import { jsx, css } from "@emotion/core"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Container, Wrapper } from "./styles"

const HowTo = () => {
  const data = useStaticQuery(graphql`
    query {
      phone: file(relativePath: { eq: "phone-pix-email.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      phoneMobile: file(relativePath: { eq: "phone-pix-email-mobile.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Wrapper>
        <div>
          <h2>
            <small>Como</small>Fazer?
          </h2>
          <p>Você não precisa mais digitar o número da agência e conta.</p>
          <ul>
            <li>
              1 - Ao abrir a tela do PIX, escolha a opção{" "}
              <strong>E-mail</strong>
            </li>
            <li>
              2 - Em seguida, digite o e-mail{" "}
              <strong>doar@universal.org</strong>
            </li>
            <li>3 - Escolha o valor que deseja doar</li>
          </ul>
        </div>

        <Img
          fluid={data.phoneMobile.childImageSharp.fluid}
          css={theme => css`
            width: 100%;
            margin-left: -25px;

            ${theme.queries.large} {
              display: none !important;
            }
          `}
        />

        <Img
          fluid={data.phone.childImageSharp.fluid}
          css={theme => css`
            width: 520px;
            margin-left: 40px;

            ${theme.queries.small} {
              display: none !important;
            }
          `}
        />
      </Wrapper>
    </Container>
  )
}

export default HowTo
