import { css } from "@emotion/core"
import styled from "@emotion/styled"

export const Container = styled.section`
  ${({ theme }) => css`
    padding: 40px 25px 0;

    h2 {
      font-weight: bold;
      font-size: 36px;
      line-height: 70px;
      text-transform: uppercase;
      color: #203760;
      line-height: 34px;
      margin-bottom: 26px;

      small {
        color: #00a1ff;
        font-size: 16px;
        display: block;
        margin-bottom: 0px;
      }
    }

    p {
      font-size: 14px;
      line-height: 21px;
      color: #203760;
      margin-bottom: 25px;
    }

    ${theme.queries.large} {
      padding: 90px 0 0;

      h2 {
        font-size: 72px;
        line-height: 70px;
        margin-top: 40px;

        small {
          font-size: 36px;
          line-height: 40px;
        }
      }

      p {
        font-size: 20px;
        line-height: 30px;
      }
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: 1200px;
    position: relative;
    margin: 0 auto;

    ${theme.queries.large} {
      display: flex;
      justify-content: center;
      flex-direction: row-reverse;
    }
  `}
`
