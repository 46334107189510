/** @jsx jsx */
import { jsx } from "@emotion/core"

import Layout from "../../components/Layout"
import SEO from "../../components/seo"

import Access from "../../components/pages/PixTutorial/Access"
import Hero from "../../components/pages/PixTutorial/Hero"
import HowTo from "../../components/pages/PixTutorial/HowTo"
import Receipt from "../../components/pages/PixTutorial/Receipt"


const PixTutorial = ({ pageContext: translation }) => {
  return (
    <Layout translation={translation}>
      <SEO
        lang={translation.currentLang}
        title={translation.pageName}
        description="Aprenda a usar o Pix"
      />
      <Hero />
      <Access />
      <HowTo />
      <Receipt />
    </Layout>
  )
}

export default PixTutorial
