import { css } from "@emotion/core"
import styled from "@emotion/styled"

import Background from "../../../../assets/images/pix-background.png"

export const Container = styled.section`
  ${({ theme }) => css`
    background-color: #fff;
    padding: 70px 36px;
    position: relative;

    svg {
      display: block;
      margin: 0 auto;
    }

    h2 {
      font-weight: bold;
      font-size: 40px;
      line-height: 106.2%;
      text-transform: uppercase;
      color: #00a1ff;
      margin-top: 11px;
    }

    h3 {
      text-transform: none;
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
      color: #203760;
      margin-top: 42px;
      width: 260px;
    }

    aside {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 106.2%;
      color: #00a1ff;

      margin-top: 65px;
      text-align: center;

      svg {
        margin-top: 10px;
      }
    }

    ${theme.queries.small} {
      .phone {
        display: none !important;
      }
    }

    ${theme.queries.large} {
      background-image: url(${Background});
      background-position: 40% center;
      background-repeat: no-repeat;
      background-size: 760px auto;

      padding: 160px 90px 90px;

      h2 {
        font-size: 80px;
        margin-top: 20px;
      }

      h3 {
        font-size: 30px;
        line-height: 36px;
        width: 430px;
      }

      aside {
        margin-top: 100px;
        display: flex;
        align-items: baseline;
        width: fit-content;

        svg {
          margin-left: 20px;
        }
      }

      .phone {
        position: absolute;
        right: 90px;
        bottom: 0;
        margin-bottom: -91px;
      }

      .pix {
        ${theme.queries.large} {
          position: absolute;
          bottom: 130px;
          right: 190px;
          z-index: 1;
          width: 240px;
          height: auto;
        }
      }
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: 1200px;
    position: relative;
    margin: 0 auto;
  `}
`
