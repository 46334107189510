/** @jsx jsx */
import { jsx } from "@emotion/core"

import { Container, Wrapper } from "./styles"

import Pix from "../../../../assets/images/svg/pix-tutorial/pix.svg"
import ArrowDown from "../../../../assets/images/svg/pix-tutorial/arrow-down.svg"
import Phone from "../../../../assets/images/svg/pix-tutorial/phone-blank.svg"
import SeoPix from "../../../SeoPix";

const Hero = () => {
  return (
    <Container>
      <Wrapper>
        <Pix className="pix" />

        <h3>Transfira suas doações com segurança, rapidez e sem tarifas.</h3>
        <h2>Doe com Pix</h2>
        <SeoPix />

        <aside>
          Veja abaixo como fazer
          <ArrowDown />
        </aside>

        <Phone className="phone" />
      </Wrapper>
      
    </Container>
  )
}

export default Hero
