/** @jsx jsx */
import { jsx, css } from "@emotion/core"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Container, Wrapper } from "./styles"

const Access = () => {
  const data = useStaticQuery(graphql`
    query {
      phone: file(relativePath: { eq: "phone-pix-selected.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      phoneMobile: file(relativePath: { eq: "phone-pix-selected-mobile.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Wrapper>
        <div style={{ maxWidth: "535px" }}>
          <h2>
            <small>Como</small>Acessar?
          </h2>

          <p>No aplicativo ou site do seu banco, procure pela opção PIX.</p>

          <p>
            Essa nova modalidade está disponível 24h por dia e a transação é
            feita em menos de 10 segundos!
          </p>
        </div>

        <Img
          fluid={data.phoneMobile.childImageSharp.fluid}
          css={theme => css`
            width: 100%;
            margin: 0 auto;

            ${theme.queries.large} {
              display: none !important;
            }
          `}
        />

        <Img
          fluid={data.phone.childImageSharp.fluid}
          css={theme => css`
            width: 520px;

            ${theme.queries.small} {
              display: none !important;
            }
          `}
        />
      </Wrapper>
    </Container>
  )
}

export default Access
