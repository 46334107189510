/** @jsx jsx */
import {jsx} from "@emotion/core"

const SeoPix = () => {
  return (
      <div style={{display: "none"}}>
          <h3>Para realizar uma transferência para o CNPJ via PIX da Igreja Universal, utilize a
              chave CNPJ 29.744.778/0001-97.
              Por meio do CNPJ da Igreja Universal, se tornou simples para transferir o seu
              PIX.</h3>
          <strong>cnpj pix universal 29.744.778/0001-97</strong>
          <strong>cnpj igreja universal pix 29.744.778/0001-97</strong>
      </div>
  );
}

export default SeoPix;